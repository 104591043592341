import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ordersTableData from "layouts/ratehawkOrders/data/ordersTableData";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllActivity } from "redux/Actions/Activities";
import { fetchActivityCategories } from "redux/Actions/ActivityCategory";
import { fetchAllBookings } from "redux/Actions/Bookings";
import { fetchAllOrders } from "redux/Actions/Orders";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import instance from "../../helpers/axiosInstance";
import Loader from "examples/Loader";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DownloadingRounded, RestartAltRounded } from "@mui/icons-material";

function Points() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const crm_uuid = queryParams?.get("crm_uuid");

  const [transactions, setTransactions] = useState(null);
  const [transactionsCount, setTransactionsCount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [filter, setFilter] = useState({
    status: 0,
    crm_uuid,
    transaction_type: 0,
    transaction_name: 0,
    first_name: null,
    last_name: null,
    from: null,
    to: null,
  });

  const navigate = useNavigate();

  React.useEffect(() => {
    setFilter((prev) => ({ ...prev, crm_uuid }));
  }, [crm_uuid]);

  useEffect(() => {
    if (!transactions) {
      fetchWalletTransactions();
    }
  }, [transactions]);

  const applyFilters = () => {
    setPage(0);
    fetchWalletTransactions();
  };

  const resetFilter = () => {
    setFilter((prev) => ({
      status: 0,
      crm_uuid: null,
      transaction_type: 0,
      transaction_name: 0,
      first_name: null,
      last_name: null,
      from: null,
      to: null,
    }));

    setTransactions(null);
    setTransactionsCount(null);
    setPage(0);
  };

  const fetchWalletTransactions = async (uuid) => {
    try {
      setLoading(true);

      const { data } = await instance.post("/points/fetch-crm", {
        ...filter,
        page: page + 1,
        pageSize,
      });

      setTransactions(data?.points?.rows);
      setTransactionsCount(data?.points?.count);
      setLoading(false);
    } catch (error) {
      setLoading(true);
      alert("Something went wrong! Please refresh page or contact the developer");
    }
  };

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = async (apiData, fileName) => {
    if (!apiData || apiData?.length === 0) return alert("No orders found");

    if (!filter.from || !filter.to) {
      return alert("Please select from and to date");
    }

    const currencies = await instance
      ?.post("/convert/excel", { to: "AED" })
      .catch(() => alert("Something went wrong"));

    if (!currencies?.data?.convert) return alert("Something went wrong");

    const allCurrencies = currencies?.data?.convert;

    let allOrders;

    // let arrayOfOrders = await fetchAllOrderDetails(
    //   apiData?.map((val) => val?.rate_hawk_order_id),
    //   apiData?.map((val) => val?.hotel_id)
    // );

    const { data: rows } = await instance.post("/ratehawk-orders/fetch-crm", {
      ...filter,
      page: 1,
      pageSize: transactionsCount,
    });

    allOrders = rows?.orders?.rows;

    let newApiData = [...allOrders]?.map((order) => {
      // let obj = arrayOfOrders?.find((val) => val?.order_id === order?.rate_hawk_order_id);
      if (order?.hotel_id === "test_hotel" || order?.hotel_id === "test_hotel_do_not_book")
        return null;

      let data_finish = order?.data_finish ? JSON?.parse(order?.data_finish) : {};

      const full_recieved_amount = addCommision(order?.show_amount, order?.commission, true);

      let payment_commission;
      let payment_via;

      if (order?.stripe_uuid) {
        payment_commission = 0.049;
        payment_via = "Stripe";
      } else if (order?.ziina_uuid) {
        payment_commission = 0.04;
        payment_via = "Ziina";
      } else if (order?.jd_pb_order_number) {
        payment_commission = 0.02;
        payment_via = "PayBy";
      }

      const ziina_or_stripe_commission =
        full_recieved_amount - full_recieved_amount * payment_commission;

      const tax_amount = full_recieved_amount - ziina_or_stripe_commission;

      const expected_profit = full_recieved_amount - Number(order?.show_amount) - tax_amount;

      const currencyRateToAED = allCurrencies?.find((val) => val?.from === order?.currency);

      let localOrder = {
        "Booked On": order?.createdAt,
        "Check-in": order?.jd_checkin || "-",
        "Check-out": order?.jd_checkout || "-",
        "Customer Name": order?.jd_guest_name,
        Nationality: data_finish?.rooms ? data_finish?.rooms[0].guests[0].residency : "N/A",
        Paid: order?.is_paid,
        "Phone Number": data_finish?.user?.phone,
        "Hotel Name": order?.jd_hotel_name || "N/A",
        // "Partner order ID": order?.uuid,
        "RateHawk order id": order?.rate_hawk_order_id,
        Country: order?.country_full,
        State: order?.state,
        "Rate Hawk Amount": order?.amount,
        Currency: order?.ratehawk_currency,
        "Paid amount w/o commission": order?.show_amount,
        "Currency paid": order?.currency,
        Commission: `${order?.commission}%`,
        "Paid by": payment_via,
        "Has Free Cancellation": order?.jd_free_cancellation,
        Status: order?.status,
        "Received amount": `${showAmountWithCommission(order?.show_amount, order?.commission)} ${
          order?.currency
        }`,
        "Possible net profit":
          order?.status === "CANCELLED" || order?.status === "REFUNDED" || order?.status === "PAID"
            ? 0
            : `${expected_profit.toFixed(2)} ${order?.currency}`,
        "Possible net AED":
          order?.status === "CANCELLED" || order?.status === "REFUNDED" || order?.status === "PAID"
            ? 0
            : order?.currency === "AED"
            ? expected_profit
            : expected_profit * currencyRateToAED?.value,
        // "RateHawk status": obj?.status,
      };

      return { ...localOrder };
    });

    // console.log(newApiData);
    const ws = XLSX.utils.json_to_sheet(newApiData?.filter((val) => val)); // convert JSON to worksheet
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] }; // create a new workbook
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" }); // write the workbook
    const data = new Blob([excelBuffer], { type: fileType }); // create a blob of data
    FileSaver.saveAs(data, fileName + fileExtension); // save the blob as a file
  };

  if (loading)
    return (
      <div>
        <Loader />
      </div>
    );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} component={Paper}>
        <Box
          my={4}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "10px",
            flexWrap: "wrap",
          }}
        >
          <TextField
            placeholder="First Name"
            value={filter?.first_name}
            onChange={(e) => setFilter((prev) => ({ ...prev, first_name: e.target.value }))}
          ></TextField>
          <TextField
            placeholder="Last Name"
            value={filter?.last_name}
            onChange={(e) => setFilter((prev) => ({ ...prev, last_name: e.target.value }))}
          ></TextField>
          <TextField
            placeholder="UUID"
            value={filter?.crm_uuid}
            onChange={(e) => setFilter((prev) => ({ ...prev, crm_uuid: e.target.value }))}
          ></TextField>

          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              value={filter?.from}
              name="From"
              renderInput={(props) => <TextField {...props} />}
              inputFormat="DD/MM/YYYY"
              onChange={(date) => {
                setFilter((prev) => ({ ...prev, from: moment(date).format("YYYY-MM-DD") }));
              }}
              label="From"
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              value={filter?.to}
              name="To"
              renderInput={(props) => <TextField {...props} />}
              inputFormat="DD/MM/YYYY"
              onChange={(date) => {
                setFilter((prev) => ({ ...prev, to: moment(date).format("YYYY-MM-DD") }));
              }}
              label="To"
            />
          </LocalizationProvider>

          <FormControl>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={filter?.status}
              label="Status"
              onChange={(e) => setFilter((prev) => ({ ...prev, status: e.target.value }))}
              size="medium"
              sx={{ height: "44.13px", minWidth: "160px" }}
              variant="outlined"
            >
              <MenuItem value={0}>All</MenuItem>
              <MenuItem value={"SUCCESS"}>Success</MenuItem>
              <MenuItem value={"FAILED"}>Failed</MenuItem>
              <MenuItem value={"PENDING"}>Pending</MenuItem>
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel id="demo-simple-select-label">Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={filter?.transaction_type}
              label="Status"
              onChange={(e) => setFilter((prev) => ({ ...prev, transaction_type: e.target.value }))}
              size="medium"
              sx={{ height: "44.13px", minWidth: "160px" }}
              variant="outlined"
            >
              <MenuItem value={0}>All</MenuItem>
              <MenuItem value={"DEBIT"}>DEBIT</MenuItem>
              <MenuItem value={"CREDIT"}>CREDIT</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {/* <Box sx={{ border: "solid", display: "block" }}> */}
        <Box mb={4} sx={{ display: "flex", justifyContent: "flex-start", gap: "10px" }}>
          <Button
            onClick={() => {
              applyFilters();
            }}
            sx={{ color: "white !important" }}
            variant="contained"
          >
            Apply Filters
          </Button>
          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<RestartAltRounded />}
            sx={{ color: "white !important" }}
            onClick={() => {
              resetFilter();
            }}
          >
            Reset filters
          </Button>
          {/* <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<DownloadingRounded />}
              sx={{ color: "white !important" }}
              onClick={() => {
                if (transactions != null && transactions?.length > 0) {
                  return exportToCSV(transactions, `${moment().format("YYYY-MM-DD")} report`);
                } else {
                  return alert("Nothing to report");
                }
              }}
            >
              Export to excel
            </Button> */}
        </Box>

        <TableContainer>
          <Table sx={{ minWidth: 650, width: "100%" }} aria-label="Hotel Orders">
            <TableHead sx={{ background: "#F1F2F4", width: "100%", display: "table-header-group" }}>
              <TableRow sx={{ width: "100%" }}>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Transaction ID</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Full name</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Email</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>User</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Transaction type</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Transaction name</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Points</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Point to aed</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Status</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Description</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Date</TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            {transactions?.length > 0 ? (
              <TableBody>
                {transactions?.map((transaction, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell sx={tableCellStyle}>{transaction?.point_id}</TableCell>
                      <TableCell
                        sx={tableCellStyle}
                      >{`${transaction?.user?.first_name} ${transaction?.user?.last_name}`}</TableCell>
                      <TableCell sx={tableCellStyle}>{transaction?.user?.email}</TableCell>
                      <TableCell>
                        {transaction?.user?.username ? (
                          <Link
                            to={"/users/" + transaction?.user?.username}
                            target={"_blank"}
                            style={{ fontSize: "14px", color: "rgb(62, 137, 214)" }}
                          >
                            {transaction?.user?.username}
                          </Link>
                        ) : (
                          "N/A"
                        )}
                      </TableCell>
                      <TableCell sx={tableCellStyle}>{transaction?.transaction_type}</TableCell>
                      <TableCell sx={tableCellStyle}>{transaction?.transaction_name}</TableCell>
                      <TableCell sx={tableCellStyle}>{transaction?.amount}</TableCell>
                      <TableCell sx={tableCellStyle}>{transaction?.one_point_to_aed}</TableCell>
                      <TableCell sx={tableCellStyle}>{transaction?.status}</TableCell>
                      <TableCell sx={tableCellStyle}>{transaction?.description}</TableCell>
                      <TableCell sx={tableCellStyle}>
                        {moment(transaction?.createdAt).format("ll")}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              ""
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          sx={{ display: "flex" }}
          count={transactionsCount}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={(e, page) => {
            setPage(page);
            setTransactions(null);
          }}
          onRowsPerPageChange={(e) => {
            setPageSize(e.target.value);
            setPage(0);
            setTransactions(null);
          }}
        />
        {/* </Box> */}
      </MDBox>
    </DashboardLayout>
  );
}

export default Points;

const tableCellStyle = {
  fontSize: "14px",
};
function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

export const showAmountWithCommission = (amount, commission) => {
  const rate = Number(commission) / 100;
  const newAmount = Number(amount) + Number(amount) * rate;
  return roundCommision(newAmount).toLocaleString();
};

export const roundCommision = (number) => {
  return Math.ceil(number);
};

export const addCommision = (amount, commission = 6, ciel = true) => {
  const rate = Number(commission) / 100;
  const newAmount = Number(amount) + Number(amount) * rate;
  if (ciel) {
    return roundCommision(newAmount);
  }
  return newAmount;
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "60vw",
  maxWidth: "60vw",

  maxHeight: "60vh",
  overflow: "scroll",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
