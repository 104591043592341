import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ordersTableData from "layouts/ratehawkOrders/data/ordersTableData";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllActivity } from "redux/Actions/Activities";
import { fetchActivityCategories } from "redux/Actions/ActivityCategory";
import { fetchAllBookings } from "redux/Actions/Bookings";
import { fetchAllOrders } from "redux/Actions/Orders";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import instance from "../../helpers/axiosInstance";
import Loader from "examples/Loader";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import {
  Check,
  Close,
  Create,
  Delete,
  DownloadingRounded,
  RestartAltRounded,
} from "@mui/icons-material";
import axiosInstance from "../../helpers/axiosInstance";

function PromoCode() {
  const [promos, setPromos] = useState(null);
  const [PromosCount, setPromosCount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [filter, setFilter] = useState({
    code: null,
    discount_type: null,
    start_date: null,
    end_date: null,
    is_active: null,
    page: 1,
    pageSize: 10,
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (!promos) {
      fetchPromos();
    }
  }, [promos]);

  const applyFilters = () => {
    setPage(0);
    fetchPromos();
  };

  const resetFilter = () => {
    setFilter((prev) => ({
      code: null,
      discount_type: null,
      start_date: null,
      end_date: null,
      is_active: null,
      page: 1,
      pageSize: 10,
    }));

    setPromos(null);
    setPromosCount(null);
    setPage(0);
  };

  const fetchPromos = async () => {
    try {
      setLoading(true);

      const { data } = await instance.post("/promo-code/fetch-crm", {
        ...filter,
        page: page + 1,
        pageSize,
      });

      setPromos(data?.promo_code?.rows);
      setPromosCount(data?.promo_code?.count);
      setLoading(false);
    } catch (error) {
      setLoading(true);
      alert("Something went wrong! Please refresh page or contact the developer");
    }
  };

  const handleDelete = async (code) => {
    try {
      const confirm = window.confirm("Are you sure you want to delete this promo code ?");

      if (!confirm) return;

      const { data } = await axiosInstance.delete("/promo-code", {
        data: {
          code,
        },
      });

      alert("Deleted Promo Code successfully");
    } catch (error) {
      alert("something went wrong, please try again or contact developer");
    }
  };

  const handleEdit = (code) => {
    navigate(`/promo-code/${code}`);
  };

  if (loading)
    return (
      <div>
        <Loader />
      </div>
    );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} component={Paper}>
        <Box
          my={4}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "10px",
            flexWrap: "wrap",
          }}
        >
          <TextField
            placeholder="Code"
            value={filter?.code}
            onChange={(e) => setFilter((prev) => ({ ...prev, code: e.target.value }))}
          ></TextField>

          <FormControl>
            <InputLabel id="demo-simple-select-label">Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={filter?.discount_type}
              label="Type"
              onChange={(e) => setFilter((prev) => ({ ...prev, discount_type: e.target.value }))}
              size="medium"
              sx={{ height: "44.13px", minWidth: "160px" }}
              variant="outlined"
            >
              <MenuItem value={null}>All</MenuItem>
              <MenuItem value={"amount"}>Amount</MenuItem>
              <MenuItem value={"percentage"}>Percentage</MenuItem>
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel id="demo-simple-select-label">Active</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={filter?.transaction_type}
              label="Status"
              onChange={(e) => setFilter((prev) => ({ ...prev, is_active: e.target.value }))}
              size="medium"
              sx={{ height: "44.13px", minWidth: "160px" }}
              variant="outlined"
            >
              <MenuItem value={null}>All</MenuItem>
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>

          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              value={filter?.start_date}
              name="Start date"
              renderInput={(props) => <TextField {...props} />}
              inputFormat="DD/MM/YYYY"
              onChange={(date) => {
                setFilter((prev) => ({ ...prev, start_date: moment(date).format("YYYY-MM-DD") }));
              }}
              label="Start date"
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              value={filter?.end_date}
              name="End date"
              renderInput={(props) => <TextField {...props} />}
              inputFormat="DD/MM/YYYY"
              onChange={(date) => {
                setFilter((prev) => ({ ...prev, end_date: moment(date).format("YYYY-MM-DD") }));
              }}
              label="End date"
            />
          </LocalizationProvider>
        </Box>

        {/* <Box sx={{ border: "solid", display: "block" }}> */}
        <Box mb={4} sx={{ display: "flex", justifyContent: "flex-start", gap: "10px" }}>
          <Button
            onClick={() => {
              applyFilters();
            }}
            sx={{ color: "white !important" }}
            variant="contained"
          >
            Apply Filters
          </Button>
          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<RestartAltRounded />}
            sx={{ color: "white !important" }}
            onClick={() => {
              resetFilter();
            }}
          >
            Reset filters
          </Button>
          {/* <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<DownloadingRounded />}
              sx={{ color: "white !important" }}
              onClick={() => {
                if (transactions != null && transactions?.length > 0) {
                  return exportToCSV(transactions, `${moment().format("YYYY-MM-DD")} report`);
                } else {
                  return alert("Nothing to report");
                }
              }}
            >
              Export to excel
            </Button> */}
        </Box>

        <Box mb={4} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={() => {
              navigate("/promo-code/new");
            }}
            sx={{ color: "white !important" }}
            variant="contained"
            color="secondary"
          >
            New Promo
          </Button>
        </Box>

        <TableContainer>
          <Table sx={{ minWidth: 650, width: "100%" }} aria-label="Hotel Orders">
            <TableHead sx={{ background: "#F1F2F4", width: "100%", display: "table-header-group" }}>
              <TableRow sx={{ width: "100%" }}>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Code</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Discount</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Usage</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Usage Limit</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Active</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Description</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Start Date</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>End date</TableSortLabel>
                </TableCell>

                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Edit</TableSortLabel>
                </TableCell>

                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Delete</TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            {promos?.length > 0 ? (
              <TableBody>
                {promos?.map((promo, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell sx={tableCellStyle}>{promo?.code}</TableCell>
                      <TableCell sx={tableCellStyle}>{`${promo?.discount_amount} ${
                        promo?.discount_type === "amount" ? "AED" : "%"
                      }`}</TableCell>
                      <TableCell sx={tableCellStyle}>{promo?.usage_count}</TableCell>
                      <TableCell>{promo?.usage_limit}</TableCell>
                      <TableCell sx={tableCellStyle}>
                        {promo?.is_active ? <Check /> : <Close />}{" "}
                      </TableCell>
                      <TableCell sx={tableCellStyle}>{promo?.description}</TableCell>
                      <TableCell sx={tableCellStyle}>
                        {moment(promo?.start_date).format("ll")}
                      </TableCell>
                      <TableCell sx={tableCellStyle}>
                        {moment(promo?.end_date).format("ll")}
                      </TableCell>

                      <TableCell sx={tableCellStyle}>
                        <IconButton onClick={() => handleEdit(promo.code)}>
                          <Create color="info" />
                        </IconButton>
                      </TableCell>

                      <TableCell sx={tableCellStyle}>
                        <IconButton onClick={() => handleDelete(promo?.code)}>
                          <Delete color="error" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              ""
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          sx={{ display: "flex" }}
          count={PromosCount}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={(e, page) => {
            setPage(page);
            setPromos(null);
          }}
          onRowsPerPageChange={(e) => {
            setPageSize(e.target.value);
            setPage(0);
            setPromos(null);
          }}
        />
        {/* </Box> */}
      </MDBox>
    </DashboardLayout>
  );
}

export default PromoCode;

const tableCellStyle = {
  fontSize: "14px",
};
function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

export const showAmountWithCommission = (amount, commission) => {
  const rate = Number(commission) / 100;
  const newAmount = Number(amount) + Number(amount) * rate;
  return roundCommision(newAmount).toLocaleString();
};

export const roundCommision = (number) => {
  return Math.ceil(number);
};

export const addCommision = (amount, commission = 6, ciel = true) => {
  const rate = Number(commission) / 100;
  const newAmount = Number(amount) + Number(amount) * rate;
  if (ciel) {
    return roundCommision(newAmount);
  }
  return newAmount;
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "60vw",
  maxWidth: "60vw",

  maxHeight: "60vh",
  overflow: "scroll",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
