import { Check, Close, Create, Delete, RestartAltRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Loader from "examples/Loader";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import axiosInstance from "helpers/axiosInstance";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function Index() {
  const [sliders, setSliders] = useState(null);
  const [slidersCount, setSlidersCount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [filter, setFilter] = useState({
    hotel_id: null,
    region_id: null,
    is_active: null,
    page: 1,
    pageSize: 10,
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (!sliders) {
      fetchSliders();
    }
  }, [sliders]);

  const applyFilters = () => {
    setPage(0);
    fetchSliders();
  };

  const handleEdit = (id) => {
    navigate(`/sliders/${id}`);
  };

  const resetFilter = () => {
    setFilter((prev) => ({
      hotel_id: null,
      region_id: null,
      is_active: null,
      page: 1,
      pageSize: 10,
    }));

    setSliders(null);
    setSlidersCount(null);
    setPage(0);
  };

  const fetchSliders = async () => {
    try {
      setLoading(true);

      const { data } = await axiosInstance.post("/home-slider/fetch-all-crm", {
        ...filter,
        page: page + 1,
        pageSize,
      });

      setSliders(data?.sliders?.rows);
      setSlidersCount(data?.sliders?.count);
      setLoading(false);
    } catch (error) {
      setLoading(true);
      alert("Something went wrong! Please refresh page or contact the developer");
    }
  };

  const handleDelete = async (id) => {
    try {
      const confirm = window.confirm("Are you sure you want to delete this slider ?");

      if (!confirm) return;

      const { data } = await axiosInstance.delete("/home-slider", {
        data: {
          id,
        },
      });

      alert("Deleted Slider successfully");
    } catch (error) {
      alert("something went wrong, please try again or contact developer");
    }
  };

  if (loading)
    return (
      <div>
        <Loader />
      </div>
    );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} component={Paper}>
        <Box
          my={4}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "10px",
            flexWrap: "wrap",
          }}
        >
          <FormControl>
            <InputLabel id="demo-simple-select-label">Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={
                filter?.region_id && !filter?.hotel_id
                  ? 2
                  : filter?.hotel_id && !filter?.region_id
                  ? 1
                  : 0
              }
              label="Type"
              onChange={(e) => {
                if (e.target.value === 1) {
                  setFilter((prev) => ({ ...prev, hotel_id: true, region_id: false }));
                } else if (e.target.value === 2) {
                  setFilter((prev) => ({ ...prev, region_id: true, hotel_id: false }));
                } else {
                  setFilter((prev) => ({ ...prev, hotel_id: null, region_id: null }));
                }
              }}
              size="medium"
              sx={{ height: "44.13px", minWidth: "160px" }}
              variant="outlined"
            >
              <MenuItem value={0}>All</MenuItem>
              <MenuItem value={1}>Hotels</MenuItem>
              <MenuItem value={2}>Regions</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {/* <Box sx={{ border: "solid", display: "block" }}> */}
        <Box mb={4} sx={{ display: "flex", justifyContent: "flex-start", gap: "10px" }}>
          <Button
            onClick={() => {
              applyFilters();
            }}
            sx={{ color: "white !important" }}
            variant="contained"
          >
            Apply Filters
          </Button>
          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<RestartAltRounded />}
            sx={{ color: "white !important" }}
            onClick={() => {
              resetFilter();
            }}
          >
            Reset filters
          </Button>
        </Box>
        <Box mb={4} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={() => {
              navigate("/sliders/new");
            }}
            sx={{ color: "white !important" }}
            variant="contained"
            color="secondary"
          >
            New Slider
          </Button>
        </Box>

        <TableContainer>
          <Table sx={{ minWidth: 650, width: "100%" }} aria-label="Sliders">
            <TableHead sx={{ background: "#F1F2F4", width: "100%", display: "table-header-group" }}>
              <TableRow sx={{ width: "100%" }}>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>ID</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Title English</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Subtitle English</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Description English</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Region ID</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Hotel ID</TableSortLabel>
                </TableCell>

                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Active</TableSortLabel>
                </TableCell>

                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Edit</TableSortLabel>
                </TableCell>

                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Delete</TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            {sliders?.length > 0 ? (
              <TableBody>
                {sliders?.map((slider, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell sx={tableCellStyle}>{slider?.id}</TableCell>
                      <TableCell sx={tableCellStyle}>{slider?.title_en}</TableCell>
                      <TableCell sx={{ fontSize: "10px" }}>{slider?.subtitle_en}</TableCell>
                      <TableCell sx={{ fontSize: "10px" }}>{slider?.description_en}</TableCell>
                      <TableCell>{slider?.region_id || "-"}</TableCell>
                      <TableCell>{slider?.hotel_id || "-"}</TableCell>
                      <TableCell sx={tableCellStyle}>
                        {slider?.is_active ? <Check /> : <Close />}{" "}
                      </TableCell>

                      <TableCell sx={tableCellStyle}>
                        <IconButton onClick={() => handleEdit(slider?.id)}>
                          <Create color="info" />
                        </IconButton>
                      </TableCell>

                      <TableCell sx={tableCellStyle}>
                        <IconButton onClick={() => handleDelete(slider?.id)}>
                          <Delete color="error" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              ""
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          sx={{ display: "flex" }}
          count={slidersCount}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={(e, page) => {
            setPage(page);
            setSliders(null);
          }}
          onRowsPerPageChange={(e) => {
            setPageSize(e.target.value);
            setPage(0);
            setSliders(null);
          }}
        />
        {/* </Box> */}
      </MDBox>
    </DashboardLayout>
  );
}

export default Index;

const tableCellStyle = {
  fontSize: "14px",
};
