import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Loader from "examples/Loader";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import axiosInstance from "helpers/axiosInstance";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function Edit() {
  const { code } = useParams();
  const [promo, setPromo] = useState(null);
  const [localPromo, setLocalPromo] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleChangePromo = (name, value) => {
    setLocalPromo((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdatePromo = async (e) => {
    e.preventDefault();

    if (!localPromo?.end_date || !localPromo?.start_date)
      return alert("Please select start and end date");

    if (moment(localPromo?.end_date).isSameOrBefore(moment(localPromo?.start_date))) {
      return alert("End date must be greater than start date");
    }

    try {
      const { data } = await axiosInstance.put("/promo-code", { ...localPromo, code });

      if (data?.status === 200) {
        return alert("Promo code updated successfully");
      }
    } catch (error) {
      return alert("Something went wrong, please try again");
    }
  };

  const fetchPromo = async () => {
    try {
      const { data } = await axiosInstance.post("/promo-code/fetch", {
        code,
      });

      setPromo(data?.promo_code);
      setLocalPromo(data?.promo_code);
      setLoading(false);
    } catch (error) {
      alert("Something went wrong! Please refresh page or contact the developer");
    }
  };

  useEffect(() => {
    fetchPromo();
  }, [code]);

  if (loading)
    return (
      <div>
        <Loader />
      </div>
    );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box py={3}>
        <Grid container spacing={3} pt={6} component="form" onSubmit={handleUpdatePromo}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Code"
              variant="outlined"
              value={localPromo?.code}
              required
              disabled
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Usage limit"
              variant="outlined"
              value={localPromo?.usage_limit}
              required
              type="number"
              onChange={(e) => {
                return handleChangePromo("usage_limit", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Usage Count"
              variant="outlined"
              value={localPromo?.usage_count}
              required
              type="number"
              onChange={(e) => {
                return handleChangePromo("usage_count", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Description"
              variant="outlined"
              value={localPromo?.description}
              required
              onChange={(e) => {
                return handleChangePromo("description", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Description AR"
              variant="outlined"
              dir="rtl"
              value={localPromo?.description_ar}
              required
              onChange={(e) => {
                return handleChangePromo("description_ar", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DateTimePicker
                label="Start Date"
                onChange={(e) =>
                  setLocalPromo((prev) => ({ ...prev, start_date: moment(e).format() }))
                }
                views={["year", "month", "day", "hours", "minutes"]}
                inputFormat="YYYY-MM-DD HH:mm:ss"
                renderInput={(params) => <TextField fullWidth required {...params} />}
                value={localPromo?.start_date}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DateTimePicker
                label="End Date"
                onChange={(e) =>
                  setLocalPromo((prev) => ({ ...prev, end_date: moment(e).format() }))
                }
                views={["year", "month", "day", "hours", "minutes"]}
                inputFormat="YYYY-MM-DD HH:mm:ss"
                renderInput={(params) => <TextField fullWidth required {...params} />}
                value={localPromo?.end_date}
                // minDate={localPromo?.start_date}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Discount Amount"
              variant="outlined"
              value={localPromo?.discount_amount}
              required
              type="number"
              onChange={(e) => {
                return handleChangePromo("discount_amount", e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={localPromo?.discount_type}
                label="Status"
                onChange={(e) =>
                  setLocalPromo((prev) => ({ ...prev, discount_type: e.target.value }))
                }
                size="medium"
                sx={{ height: "44.13px", minWidth: "160px" }}
                variant="outlined"
              >
                <MenuItem value={"percentage"}>% (percentage)</MenuItem>
                <MenuItem value={"amount"}>AED (Amount)</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid item xs={12} md={6}>
              <Checkbox
                checked={localPromo?.is_active}
                onChange={() => handleChangePromo("is_active", !localPromo?.is_active)}
                inputProps={{ "aria-label": "controlled" }}
              />
              <span>Is Active</span>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              type="submit"
              variant="outlined"
              sx={{ fontSize: "24px", fontWeight: "bold", color: "black !important" }}
              // onClick={handleSavePromo}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    </DashboardLayout>
  );
}

export default Edit;
