import { Camera, CameraAlt, Close } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Loader from "examples/Loader";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import instance from "helpers/axiosInstance";
import { baseURL } from "helpers/axiosInstance";
import { debounce } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

function Edit() {
  const { id } = useParams();
  const [slider, setSlider] = useState(null);
  const [localSlider, setLocalSlider] = useState(null);
  const [loading, setLoading] = useState(true);

  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [loadingSearch, setLoadingSearch] = useState(false);

  const [thumbnail, setThumbnail] = useState(false);
  const [thumbnailFiles, setThumbnailFiles] = useState(false);

  const fetchSlider = async () => {
    try {
      const { data } = await instance.post("/home-slider/fetch", {
        id,
      });

      setSlider(data?.sliders);
      setLocalSlider(data?.sliders);
      setLoading(false);
    } catch (error) {
      alert("Something went wrong! Please refresh page or contact the developer");
    }
  };

  const fetchOptions = async (query) => {
    setLoadingSearch(true);
    try {
      const { data } = await instance.post("/ratehawk/hotels/autocomplete", {
        query,
        language: "en",
      });

      setOptions([...data?.data?.hotels, ...data?.data?.regions]);
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setLoadingSearch(false);
    }
  };

  useEffect(() => {
    fetchSlider();
  }, [id]);

  const handleChangeSlider = (name, value) => {
    setLocalSlider((prev) => ({ ...prev, [name]: value }));
  };

  const debouncedFetchOptions = useMemo(
    () => debounce(fetchOptions, 500), // 500ms debounce delay
    []
  );

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    if (newInputValue) {
      debouncedFetchOptions(newInputValue); // Call the debounced function
    } else {
      setOptions([]); // Clear options if input is empty
    }
  };

  const handleUpdateThumbnail = async (thumbnailLoc) => {
    try {
      const formData = new FormData();

      if (thumbnailLoc?.length > 0) {
        formData.append(`images`, thumbnailLoc[0], thumbnailLoc[0]?.name);

        const { data } = await axios({
          method: "POST",
          url: `${baseURL}/upload?img_dir=activity`,
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        });

        await instance.put("/home-slider", {
          thumbnail: data?.upload[0],
          id,
        });

        alert("Updated thumbnail successfully");
      } else {
        alert("No thumbnail detected");
      }
    } catch (error) {
      alert("Update Image Failed");
    }
  };

  const handleUpdateSlider = async () => {
    try {
      const { data } = await instance.put("/home-slider", {
        ...localSlider,
        id,
      });
      alert("Updated successfully");
    } catch (error) {
      alert("Something went wrong! Please refresh page or contact the developer");
    }
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];

    const promises = files.map(
      (file) =>
        // eslint-disable-next-line consistent-return
        new Promise((resolve, reject) => {
          const fileType = file.type;
          if (!allowedTypes.includes(fileType)) {
            // reject();
            // eslint-disable-next-line no-promise-executor-return
            return alert("Only allowed types are: jpg, jpeg, png");
          }
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        })
    );

    Promise.all(promises)
      .then((image) => {
        setThumbnail(image);
        setThumbnailFiles(files?.filter((val) => allowedTypes.includes(val?.type)));
        // setCoverFile(e.target.files);
      })
      .catch((error) => console.error(error));
  };

  if (loading)
    return (
      <div>
        <Loader />
      </div>
    );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={2}>
        {localSlider?.thumbnail && (
          <Box
            sx={{
              width: "100%",
              height: "50vh",
              background: `url(${slider?.thumbnail}) center center / cover no-repeat`,
              borderRadius: "15px",
              position: "relative",
            }}
          >
            <Box sx={{ position: "absolute", right: 10, bottom: 10 }}>
              <Button
                sx={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "white !important",
                  background: "rgb(33, 33, 33)",
                  ":hover": {
                    background: "rgba(33, 33, 33, 0.7)",
                  },
                  display: "flex",
                  gap: "10px",
                }}
                onClick={() => handleChangeSlider("thumbnail", null)}
              >
                <CameraAlt sx={{ fontSize: "18px !important" }} fontSize="inherit" />
                Remove
              </Button>
            </Box>
          </Box>
        )}
        {!localSlider?.thumbnail && (!thumbnail || thumbnail?.length === 0) && (
          <Box
            sx={{
              width: "100%",
              height: "50vh",
              borderRadius: "15px",
              background: `linear-gradient(to right, #2193b0, #6dd5ed)`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <Typography color="#FFF" sx={{ position: "absolute", top: "45%", left: "43%" }}>
              Upload Image
            </Typography>
            <input
              type="file"
              accept="image/jpeg,image/jpg,image/png"
              style={{ opacity: "0", width: "100%", height: "100%", cursor: "pointer" }}
              onChange={handleImageChange}
            />
          </Box>
        )}
        {!localSlider?.thumbnail && thumbnail && thumbnail?.length > 0 && (
          <Box
            sx={{
              width: "100%",
              height: "50vh",
              background: `url(${thumbnail[0]}) center center / cover no-repeat`,
              borderRadius: "15px",
              position: "relative",
            }}
          >
            <Box sx={{ position: "absolute", right: 10, top: 10 }}>
              <IconButton
                onClick={() => {
                  setThumbnail(null);
                  setThumbnailFiles(null);
                }}
                sx={{
                  background: "rgba(255,255,255, 0.5)",
                  ":hover": {
                    background: "rgba(255,255,255, 0.5)",
                  },
                }}
              >
                <Close />
              </IconButton>
            </Box>
            <Box sx={{ position: "absolute", right: 10, bottom: 10 }}>
              <Button
                sx={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "white !important",
                  background: "rgb(33, 33, 33)",
                  ":hover": {
                    background: "rgba(33, 33, 33, 0.7)",
                  },
                  display: "flex",
                  gap: "10px",
                }}
                onClick={() => {
                  handleUpdateThumbnail(thumbnailFiles);
                }}
              >
                <CameraAlt sx={{ fontSize: "18px !important" }} fontSize="inherit" />
                Save
              </Button>
            </Box>
          </Box>
        )}
        <Grid container spacing={3} pt={5}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Title EN"
              variant="outlined"
              value={localSlider?.title_en}
              onChange={(e) => {
                return handleChangeSlider("title_en", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Title AR"
              variant="outlined"
              dir="rtl"
              value={localSlider?.title_ar}
              onChange={(e) => {
                return handleChangeSlider("title_ar", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Subtitle EN"
              variant="outlined"
              value={localSlider?.subtitle_en}
              multiline
              rows={3}
              onChange={(e) => {
                return handleChangeSlider("subtitle_en", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Subtitle AR"
              variant="outlined"
              dir="rtl"
              value={localSlider?.subtitle_ar}
              multiline
              rows={3}
              onChange={(e) => {
                return handleChangeSlider("subtitle_ar", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Description EN"
              variant="outlined"
              value={localSlider?.description_en}
              multiline
              rows={3}
              onChange={(e) => {
                return handleChangeSlider("description_en", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Description AR"
              variant="outlined"
              dir="rtl"
              value={localSlider?.description_ar}
              multiline
              rows={3}
              onChange={(e) => {
                return handleChangeSlider("description_ar", e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Hotel ID"
              variant="outlined"
              value={localSlider?.hotel_id}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Region ID"
              variant="outlined"
              value={localSlider?.region_id}
              disabled
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Checkbox
              checked={localSlider?.is_active}
              onChange={() => handleChangeSlider("is_active", !localSlider?.is_active)}
              inputProps={{ "aria-label": "controlled" }}
            />
            <span>Is Active</span>
          </Grid>

          <Grid item xs={12} md={6}>
            <Autocomplete
              options={options}
              loading={loadingSearch}
              inputValue={inputValue}
              onInputChange={handleInputChange}
              getOptionLabel={(option) => option.name || ""}
              groupBy={(option) => (option?.type ? "Regions" : "Hotels")}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(_, value) => {
                if (value?.type) {
                  handleChangeSlider("region_id", value?.id);
                  handleChangeSlider("hotel_id", null);
                } else if (value?.hid) {
                  handleChangeSlider("hotel_id", value?.id);
                  handleChangeSlider("region_id", null);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loadingSearch ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
        <Box sx={{ mt: 5, width: "100%", display: "flex", justifyContent: "center" }}>
          <Button
            variant="outlined"
            sx={{ fontSize: "24px", fontWeight: "bold", color: "black !important" }}
            onClick={handleUpdateSlider}
          >
            UPDATE
          </Button>
        </Box>
      </MDBox>
    </DashboardLayout>
  );
}

export default Edit;
