/* eslint-disable no-unused-vars */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import { useEffect, useRef, useState } from "react";
import instance from "../../helpers/axiosInstance";
import moment from "moment";
import { Bar, Chart } from "react-chartjs-2";
import { Bubble } from "react-chartjs-2";

import { Box, Button, Divider, Paper, TextField, Typography } from "@mui/material";
import { Schedule } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import { updateUtil } from "redux/Actions/Utils";
import { formatNumber } from "helpers/generalFunctions";

function Dashboard() {
  // const { sales, tasks } = reportsLineChartData;
  const dispatch = useDispatch();
  const [sales, setSales] = useState([]);
  const [usersSignedup, setUsersSignedup] = useState([]);
  const [userCount, setUserCount] = useState(null);
  const [userLoginCount, setUserLoginCount] = useState(null);
  const [plansCount, setPlansCount] = useState(null);
  const [paidPlansCount, setpaidPlansCount] = useState(null);
  const [chartUpdateDate, setChartUpdateDate] = useState(null);
  const [totalRevenue, setTotalRevenue] = useState(null);
  const [operating, setOperating] = useState(null);
  const [devices, setDevices] = useState(null);
  const [localUtils, setLocalUtils] = useState(null);

  const utils = useSelector((state) => state?.utils);

  const chartRef = useRef(null);
  const chart2Ref = useRef(null);
  const chartInstanceRef = useRef(null);
  const chart2InstanceRef = useRef(null);

  useEffect(() => {
    instance.post("/admin/users").then(({ data }) => {
      setUserCount(data?.users?.users_count);
    });
    instance.post("/admin/paid-plans").then(({ data }) => {
      setpaidPlansCount(data?.plan?.plans);
    });
    instance.post("/admin/plans").then(({ data }) => {
      setPlansCount(data?.plan?.plans);
    });
    instance.post("/dashboard/revenue").then(({ data }) => {
      setSales(data?.plans?.sales);

      setChartUpdateDate(moment());
    });

    instance.post("/dashboard/users").then(({ data }) => {
      setUsersSignedup(data?.users?.sales);
      // setChartUpdateDate(moment());
    });

    instance.post("/dashboard/total").then(({ data }) => {
      setTotalRevenue(data?.revenue?.revenue);
      // setChartUpdateDate(moment());
    });

    instance.post("/dashboard/operating").then(({ data }) => {
      setOperating(data.users);
    });

    instance.post("/dashboard/devices").then(({ data }) => {
      setDevices(data.devices);
    });

    instance.post("/dashboard/users-login").then(({ data }) => {
      console.log(data?.users);
      setUserLoginCount(data.users);
    });
  }, []);

  useEffect(() => {
    if (utils && utils?.length > 0) return setLocalUtils(utils);
  }, [utils]);

  const handleChangeUtils = (index, name, val) => {
    let newArray = [...localUtils];
    newArray[index][name] = Number(val);
    setLocalUtils(newArray);
  };

  const handleSaveUtil = (index) => {
    let obj = localUtils[index];

    dispatch(updateUtil(obj));
  };

  function calculatePercentageIncrease(currentRevenue, previousRevenue, array) {
    if (array?.length < 2) return;

    const revenueDifference = currentRevenue - previousRevenue;
    const percentageIncrease = (revenueDifference / previousRevenue) * 100;

    return percentageIncrease;
  }

  useEffect(() => {
    if (operating && operating?.length > 0) {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }

      const ctx = chartRef.current.getContext("2d");
      const newChartInstance = new Chart(ctx, {
        type: "pie",
        data: {
          labels: ["IOS", "Android", "Website"],
          datasets: [
            {
              label: "My First Dataset",
              data: operating || [0, 0, 0],
              backgroundColor: ["#45474B", "#2EDF85", "#00AAFF"],
              hoverOffset: 4,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
        },
      });

      chartInstanceRef.current = newChartInstance;
    }
  }, [operating]);

  useEffect(() => {
    if (devices && devices?.length > 0) {
      if (chart2InstanceRef.current) {
        chart2InstanceRef.current.destroy();
      }

      const ctx = chart2Ref.current.getContext("2d");
      const newChartInstance = new Chart(ctx, {
        type: "pie",
        data: {
          labels: ["IOS", "Android"],
          datasets: [
            {
              label: "My First Dataset",
              data: devices || [0, 0],
              backgroundColor: ["#45474B", "#2EDF85", "#00AAFF"],
              hoverOffset: 4,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
        },
      });

      chart2InstanceRef.current = newChartInstance;
    }
  }, [devices]);

  const data = {
    labels: ["Dubai", "Abu Dhabi", "Riyadh"],
    datasets: [
      {
        label: "Number Of Users",
        data: [25, 40, 35],
        backgroundColor: "rgba(75, 192, 192, 0.6)",
      },
    ],
  };

  const options = {
    scales: {
      x: { stacked: true },
      y: { stacked: true },
    },
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="person"
                title="Users"
                count={userCount || 0}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="rss_feed"
                title="Users last 24hrs"
                count={userLoginCount || 0}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard icon="luggage" title="All plans" count={plansCount || 0} />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="credit_card"
                title="Paid Plans"
                count={paidPlansCount}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="warning"
                icon="credit_card"
                title="Total Revenue"
                count={`${totalRevenue ? formatNumber(totalRevenue) : 0} AED`}
              />
            </MDBox>
          </Grid>
          {/* <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Followers"
                count="+91"
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Just updated",
                }}
              />
            </MDBox>
          </Grid> */}
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            {/* <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="website views"
                  description="Last Campaign Performance"
                  date="campaign sent 2 days ago"
                  chart={reportsBarChartData}
                />
              </MDBox>
            </Grid> */}
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="Monthly Sales"
                  description={
                    <>
                      {sales?.datasets?.data?.length < 2 ? null : (
                        <>
                          (
                          <strong>
                            {calculatePercentageIncrease(
                              sales?.datasets?.data[sales?.datasets?.data?.length - 1],
                              sales?.datasets?.data[sales?.datasets?.data?.length - 2]
                            ).toFixed(1)}
                            %
                          </strong>
                          ){" "}
                          {calculatePercentageIncrease(
                            sales?.datasets?.data[sales?.datasets?.data?.length - 1],
                            sales?.datasets?.data[sales?.datasets?.data?.length - 2]
                          ).toFixed(2) > 0
                            ? "increase"
                            : "decrease"}{" "}
                          in monthly sales.
                        </>
                      )}
                    </>
                  }
                  date={`updated ${moment(chartUpdateDate).fromNow()}`}
                  chart={sales}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="info"
                  title="Monthly Users"
                  description={
                    <>
                      {usersSignedup?.datasets?.data?.length < 2 ? null : (
                        <>
                          (
                          <strong>
                            {calculatePercentageIncrease(
                              usersSignedup?.datasets?.data[
                                usersSignedup?.datasets?.data?.length - 1
                              ],
                              usersSignedup?.datasets?.data[
                                usersSignedup?.datasets?.data?.length - 2
                              ]
                            ).toFixed(1)}
                            %
                          </strong>
                          ){" "}
                          {calculatePercentageIncrease(
                            usersSignedup?.datasets?.data[
                              usersSignedup?.datasets?.data?.length - 1
                            ],
                            usersSignedup?.datasets?.data[usersSignedup?.datasets?.data?.length - 2]
                          ).toFixed(2) > 0
                            ? "increase"
                            : "decrease"}{" "}
                          in new users.
                        </>
                      )}
                    </>
                  }
                  date={`updated ${moment(chartUpdateDate).fromNow()}`}
                  chart={usersSignedup}
                />
              </MDBox>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <Box
                sx={{
                  width: "100%",
                  position: "relative",
                  // height: "fit-content",
                  background: "#FAFAFA",
                  boxShadow:
                    "0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1), 0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06)",
                  height: "400px",
                  borderRadius: "15px",
                }}
              >
                <Box
                  sx={{
                    margin: "0 auto",
                    borderRadius: "10px",
                    overflow: "hidden",
                    width: "92%",
                    background: "#bdbdbd",
                    height: "300px",
                    boxShadow:
                      "0rem 0.25rem 1.25rem 0rem rgba(0, 0, 0, 0.14), 0rem 0.4375rem 0.625rem -0.3125rem rgba(0, 187, 212, 0.4)",
                    position: "absolute",
                    top: "-25px",
                    left: "16px",
                    padding: "10px 5px",
                  }}
                >
                  <canvas ref={chartRef}></canvas>
                </Box>
                <Box sx={{ padding: "20px", paddingTop: "300px" }}>
                  <Typography variant="h5">Users signed-up from</Typography>
                  <Divider />
                  <Box
                    sx={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                      fontSize: "14px",
                      color: "#7b809a",
                    }}
                  >
                    <Schedule style={{ fontSize: "inherit" }} />
                    <Typography sx={{ fontSize: "14px" }}>
                      {`updated ${moment(chartUpdateDate).fromNow()}`}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Box
                sx={{
                  width: "100%",
                  position: "relative",
                  // height: "fit-content",
                  background: "#FAFAFA",
                  boxShadow:
                    "0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1), 0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06)",
                  height: "400px",
                  borderRadius: "15px",
                }}
              >
                <Box
                  sx={{
                    margin: "0 auto",
                    borderRadius: "10px",
                    overflow: "hidden",
                    width: "92%",
                    background: "#bdbdbd",
                    height: "300px",
                    boxShadow:
                      "0rem 0.25rem 1.25rem 0rem rgba(0, 0, 0, 0.14), 0rem 0.4375rem 0.625rem -0.3125rem rgba(0, 187, 212, 0.4)",
                    position: "absolute",
                    top: "-25px",
                    left: "16px",
                    padding: "10px 5px",
                  }}
                >
                  <canvas ref={chart2Ref}></canvas>
                </Box>
                <Box sx={{ padding: "20px", paddingTop: "300px" }}>
                  <Typography variant="h5">Devices OS Usage Insights</Typography>
                  <Divider />
                  <Box
                    sx={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                      fontSize: "14px",
                      color: "#7b809a",
                    }}
                  >
                    <Schedule style={{ fontSize: "inherit" }} />
                    <Typography sx={{ fontSize: "14px" }}>
                      {`updated ${moment(chartUpdateDate).fromNow()}`}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </MDBox>
        {localUtils && (
          <MDBox mt={4.5}>
            <Grid container spacing={2}>
              {localUtils?.length > 0 &&
                localUtils?.map((util, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Paper elevation={2} sx={{ borderRadius: "10px", padding: "10px" }}>
                      <Box>
                        <Typography variant="h5" textAlign={"center"}>
                          {util?.name?.replace("_", " ")?.toLowerCase()}
                        </Typography>
                      </Box>
                      <Box component={"form"} sx={{ mt: 2 }}>
                        <TextField
                          id="outlined-basic"
                          label="Outlined"
                          variant="outlined"
                          type="number"
                          // defaultValue={util?.value}
                          value={util?.value}
                          fullWidth
                          onChange={({ target }) => {
                            handleChangeUtils(index, "value", target?.value);
                          }}
                        />
                        <Button
                          sx={{
                            mt: 1,
                            width: "100%",
                            color: "#FFF",
                            background: "#1A73E8",
                            ":hover": {
                              color: "#FFF",
                              background: "rgba(26, 115, 232, 0.7)",
                              transition: "all 0.5s",
                            },
                          }}
                          onClick={() => {
                            handleSaveUtil(index);
                          }}
                        >
                          Save
                        </Button>
                      </Box>
                    </Paper>
                  </Grid>
                ))}
            </Grid>
          </MDBox>
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default Dashboard;
